import React from "react"
import { GatsbyProps } from "Src/global-types"

import StandardLayout from "Components/core/StandardLayout"
import SEO from "Components/core/SEO"

const Tinkering: React.FC<GatsbyProps> = ({ location }) => {
  return (
    <StandardLayout location={location}>
      <SEO title="Playing With Hexagons" />
      <h2>Playing with Hexagons</h2>
      <iframe
        height="300"
        style={{ width: "100%" }}
        scrolling="no"
        title="Playing with Polygons: The Start - V1"
        src="https://codepen.io/liammyles/embed/preview/EQdajJ?height=265&theme-id=dark&default-tab=result"
        frameBorder="no"
      >
        See the Pen{" "}
        <a href="https://codepen.io/liammyles/pen/EQdajJ">
          Playing with Polygons: The Start - V1
        </a>{" "}
        by Liam Myles (<a href="https://codepen.io/liammyles">@liammyles</a>) on{" "}
        <a href="https://codepen.io">CodePen</a>.
      </iframe>
      <iframe
        height="300"
        style={{ width: "100%" }}
        scrolling="no"
        title="Playing with Polygons: Painting Trail - V2"
        src="https://codepen.io/liammyles/embed/preview/pamJOp?height=2300&theme-id=dark&default-tab=result"
        frameBorder="no"
      >
        See the Pen{" "}
        <a href="https://codepen.io/liammyles/pen/pamJOp">
          Playing with Polygons: Painting Trail - V2
        </a>{" "}
        by Liam Myles (<a href="https://codepen.io/liammyles">@liammyles</a>) on{" "}
        <a href="https://codepen.io">CodePen</a>.
      </iframe>
      <iframe
        height="300"
        style={{ width: "100%" }}
        scrolling="no"
        title="Playing with Polygons: Fade Trail - V3"
        src="https://codepen.io/liammyles/embed/preview/jZoPda?height=2300&theme-id=dark&default-tab=result"
        frameBorder="no"
      >
        See the Pen{" "}
        <a href="https://codepen.io/liammyles/pen/jZoPda">
          Playing with Polygons: Fade Trail - V3
        </a>{" "}
        by Liam Myles (<a href="https://codepen.io/liammyles">@liammyles</a>) on{" "}
        <a href="https://codepen.io">CodePen</a>.
      </iframe>
      <iframe
        height="300"
        style={{ width: "100%" }}
        scrolling="no"
        title="Playing with Polygons: Scale and Transparency - V4"
        src="https://codepen.io/liammyles/embed/preview/PRqmEJ?height=265&theme-id=dark&default-tab=result"
        frameBorder="no"
      >
        See the Pen{" "}
        <a href="https://codepen.io/liammyles/pen/PRqmEJ">
          Playing with Polygons: Scale and Transparency - V4
        </a>{" "}
        by Liam Myles (<a href="https://codepen.io/liammyles">@liammyles</a>) on{" "}
        <a href="https://codepen.io">CodePen</a>.
      </iframe>
      <iframe
        height="300"
        style={{ width: "100%" }}
        scrolling="no"
        title="Playing with Polygons: Spiral Painter - V5"
        src="https://codepen.io/liammyles/embed/preview/pLJPpQ?height=3300&theme-id=dark&default-tab=result"
        frameBorder="no"
      >
        See the Pen{" "}
        <a href="https://codepen.io/liammyles/pen/pLJPpQ">
          Playing with Polygons: Spiral Painter - V5
        </a>{" "}
        by Liam Myles (<a href="https://codepen.io/liammyles">@liammyles</a>) on{" "}
        <a href="https://codepen.io">CodePen</a>.
      </iframe>
    </StandardLayout>
  )
}

export default Tinkering
